<template>
  <router-view/>
  <button @click="modal1.open({ view: 'AllWallets' })">链接钱包</button>
  <button @click="disconnect">断开链接</button>
  <button @click="transfer">转账</button>
  <div style="font-size: 14px;">{{ address }}</div>
</template>

<script setup>
  import { ref, watch } from 'vue'
  import {
    createWeb3Modal,
    defaultConfig,
    useWeb3Modal,
    useWeb3ModalAccount,
    useDisconnect
  } from '@web3modal/ethers/vue'
  import { BrowserProvider, Contract, formatUnits, parseEther } from 'ethers'

  const projectId = 'cb54bf6ac19e0d2c184c0b1ec9c83e8a'

  const mainnet = {
    chainId: 56,
    name: 'BSC',
    currency: 'BSC',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://bscscan.com'
  }

  const metadata = {
    name: 'climber',
    description: 'AppKit Example',
    url: 'https://www.xiemail.shop', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/37784886']
  }

  const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: 'https://bscscan.com', // used for the Coinbase SDK
    defaultChainId: 56, // used for the Coinbase SDK
  })

  const modal = createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    enableOnramp: true, // Optional - false as default
    themeMode: 'light'
  })
  const walletProvider = modal.getWalletProvider()
  const ethersProvider = new BrowserProvider(walletProvider)
  // const signer = await ethersProvider.getSigner()
  const modal1 = useWeb3Modal()

  const address = ref('')
  const wAddr = useWeb3ModalAccount().address
  let USDTContract
  watch(wAddr, async (newValue, oldValue) => {
    address.value = newValue
    // const ethersProvider = new BrowserProvider(walletProvider)
    /*const walletProvider = modal.getWalletProvider()
    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    USDTContract = new Contract(contractConfig.usdtAddress, usdtAbi, signer)
    const USDTBalance = await USDTContract.balanceOf(newValue)
    console.log(formatUnits(USDTBalance, 18))*/
  })

  async function transfer() {
    try {
      const etherNum = parseEther('0.01')
      const res = await USDTContract.transfer('0x178b31014f9E0b216e219474Fc6116A90105df66', etherNum)
      if(res) {
        console.log(666)
      }
    } catch (e) {
      console.log(e)
    }
  }

  function disconnect() {
    const { disconnect } = useDisconnect()
    disconnect()
  }

</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
